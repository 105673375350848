<template>
  <v-container>
    <v-row>
      <v-col class="text-right">
        <v-btn color="primary" @click="openPostDialog">发表新帖</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- 帖子列表 -->
        <v-data-table :headers="headers" :items="posts.content" :page.sync="page" :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :server-items-length="posts.totalElements" class="elevation-1"
          :no-data-text="loading ? '正在加载数据' : '暂无帖子'" :footer-props="{ 'items-per-page-text': '每页行数' }"
          @update:items-per-page="fetchPosts">
          <template v-slot:item.title="{ item }">
            <router-link :to="{ name: 'post', params: { id: item.id } }">{{ item.title }}</router-link>
          </template>
          <template v-slot:item.account="{ item }">
            {{ item.account.username }}
          </template>
          <template v-slot:item.replyCount="{ item }">
            {{ item.replyCount }}
          </template>
          <template v-slot:item.lastReplyAccount="{ item }">
            {{ item.lastReplyAccount.username }}
          </template>
          <template v-slot:item.lastReplyTime="{ item }">
            {{ formatDateTime(item.lastReplyTime) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- 发帖对话框 -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">发表新帖</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="标题" v-model="newPost.title" :rules="[v => !!v || '标题不能为空']" required ref="titleInput"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <quill-editor ref="editor" v-model="newPost.content" :options="editorOptions"></quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
            <v-btn color="blue darken-1" text @click="createPost">发表</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import service from '@/utils/request';

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  name: "ForumPosts",
  components: {
    quillEditor
  },
  inject: ['showMessage'],
  data() {
    return {
      loading: false,
      page: 1,
      itemsPerPage: 10,
      sortBy: ['updateTime'],
      sortDesc: [true],
      headers: [
        { text: '标题', value: 'title', sortable: false, width: 'auto' },
        { text: '作者', value: 'account.username', sortable: false, width: '200px' },
        { text: '回复次数', value: 'replyCount', sortable: false, width: '100px' },
        { text: '最后回复者', value: 'lastReplyAccount.username', sortable: false, width: '200px' },
        { text: '最后回复时间', value: 'lastReplyTime', sortable: false, width: '300px' },
      ],
      posts: [], // 存储帖子列表
      dialog: false, // 控制发帖对话框的显示
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体大小
              ['bold', 'italic', 'underline', 'strike'],        // 加粗、斜体、下划线、删除线
              [{ 'color': [] }, { 'background': [] }],          // 字体颜色、背景颜色
              ['link'],                                         // 插入链接
              ['blockquote', 'code-block'],                     // 引用、代码块
              ['image'],                                        // 添加图片按钮
            ],
            handlers: {
              'image': this.imageHandler                         // 图片处理函数
            }
          },
        },
        placeholder: "请输入内容...",
        theme: 'snow'  // 主题
      },
      newPost: { // 存储新帖子的数据
        title: '',
        content: ''
      }
    }
  },
  watch: {
    page: 'fetchPosts',
    sortBy: 'fetchPosts',
    sortDesc: 'fetchPosts',
  },
  created() {
    this.fetchPosts();
  },
  methods: {
    formatDateTime(dateTimeStr) {
      const date = new Date(dateTimeStr);
      return date.toLocaleString();
    },
    fetchPosts() {
      let sort = 'updateTime,desc'
      if (this.sortBy[0] && this.sortDesc[0]) {
        sort = `${this.sortBy[0]},desc`
      } else if (this.sortBy[0]) {
        sort = this.sortBy[0]
      }

      const params = {
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: sort,
      };

      this.loading = true;
      service.get('/wikiapi/forum/posts', { params })
        .then(response => {
          this.posts = response.data;
        })
        .catch(error => {
          this.showMessage(error.response.data.message, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openPostDialog() {
      this.dialog = true;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.titleInput) {
            this.$refs.titleInput.focus();
          }
        }, 500); // 增加延迟时间
      });
    },
    createPost() {
      if (this.$refs.form.validate()) {
        // 发送新帖子数据到后端API
        service.post('/wikiapi/forum/posts', this.newPost)
          .then(() => {
            this.dialog = false;
            this.newPost.title = '';
            this.newPost.content = '';
            this.fetchPosts(); // 重新加载帖子列表

            this.showMessage('帖子发表成功');
          })
          .catch(error => {
            this.showMessage(error.response.data.message, 'error');
          });
        // ...
      } else {
        // 至少有一个字段没有通过验证，不提交表单
      }
    },
    imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();
      input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const response = await service.post('/wikiapi/forum/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const range = this.$refs.editor.quill.getSelection();
        this.$refs.editor.quill.insertEmbed(range.index, 'image', response.data.url);
      };
    }
  }
};
</script>

<style>
</style>
